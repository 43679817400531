import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ac6f5cda"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "editorBox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CompUpdateList = _resolveComponent("CompUpdateList");
  const _component_van_list = _resolveComponent("van-list");
  const _component_Error = _resolveComponent("Error");
  return _openBlock(), _createElementBlock("div", null, [$data.update_list.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
    key: 0,
    loading: $data.updateLoading,
    "onUpdate:loading": _cache[0] || (_cache[0] = $event => $data.updateLoading = $event),
    finished: !$data.is_next,
    "immediate-check": false,
    onLoad: $options.onLoad
  }, {
    default: _withCtx(() => [_createVNode(_component_CompUpdateList, {
      update_list: $data.update_list
    }, null, 8, ["update_list"])]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, null, 512), $data.showError ? (_openBlock(), _createBlock(_component_Error, {
    key: 1,
    message: $data.showMessage
  }, null, 8, ["message"])) : _createCommentVNode("", true)]);
}