import Error from "@@/common/Error.vue";
import CompUpdateList from "@@/idea/updateList.vue";
import cont_match_reg from "@/utils/editor.js";
import { getHistoryIdeaContent, getIdeaUpdateList } from "@api/index";
export default {
  components: {
    CompUpdateList,
    Error
  },
  data() {
    return {
      showError: false,
      showMessage: "",
      content: '',
      update_list: [],
      page: 0,
      page_size: 10,
      is_next: true,
      updateLoading: false
    };
  },
  async beforeMount() {
    const idea_id = this.$route.params.id ? this.$route.params.id.replace(".html", "") : "";
    this.idea_id = idea_id;
    await this.getUpdateList();
  },
  async mounted() {},
  methods: {
    // list 方法
    onLoad() {
      this.getUpdateList();
    },
    // 获取更新列表
    async getUpdateList() {
      this.page++;
      const {
        status,
        data
      } = await getIdeaUpdateList({
        idea_id: this.idea_id,
        page: this.page,
        page_size: this.page_size
      });
      console.log(this.page);
      if (status == 0 && data && data.list) {
        this.updateLoading = false;
        data.list.forEach(ele => {
          ele.if_video_play = false;
          let dateObj = new Date(ele.create_time.replace(/-/g, '/'));
          ele.isOrderThanNow = dateObj.getFullYear() < new Date().getFullYear();
          ele.year = dateObj.getFullYear();
          ele.month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : '0' + (dateObj.getMonth() + 1);
          ele.date = dateObj.getDate() > 9 ? dateObj.getDate() : '0' + dateObj.getDate();
          this.update_list.push(ele);
        });
        this.is_next = data.is_next;
        // 请求历史数据的富文本
        if (!data.is_next) {
          await this.getHistoryContent();
        }
      } else {
        this.is_next = false;
        await this.getHistoryContent();
      }
    },
    // 获取历史富文本
    async getHistoryContent() {
      const {
        data,
        status,
        message
      } = await getHistoryIdeaContent({
        idea_id: this.idea_id
      });
      if (status == 0) {
        this.content = data ? data.content : '';
      } else if (status == '999999') {
        this.showError = true;
        this.showMessage = "网络错误";
        this.sensors.track('IdeaClientDetail', {
          Type: "网络错误"
        });
      } else {
        this.showError = true;
        this.showMessage = message || "什么都没有哦";
        this.sensors.track('IdeaClientDetail', {
          Type: "接口错误",
          Message: message
        });
      }
      if (!this.showError && this.$refs.editorBox && this.content) {
        this.$refs.editorBox.appendChild(cont_match_reg(this.content));
      }
    }
  }
};